import React, { createContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { theme } from 'theme';
import { EnumScreens } from 'theme/helpers/css';

export const DeviceContext = createContext({});

export enum EnumDevices {
  MOBILE = 'mobile',
  PC = 'pc',
}

const DeviceProvider: React.FC = ({ children }) => {
  const [device, setDevice] = useState<EnumDevices>();

  const determineDevice = () => {
    if (window.innerWidth >= theme.breakpoints[EnumScreens.lg]) setDevice(EnumDevices.PC);
    else setDevice(EnumDevices.MOBILE);
  };

  useEffect(() => {
    if (window) {
      determineDevice();
      window.addEventListener('resize', determineDevice);
      return () => window.removeEventListener('resize', determineDevice);
    }
  }, []);

  return <DeviceContext.Provider value={device}>{children}</DeviceContext.Provider>;
};

export default DeviceProvider;
