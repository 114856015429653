import styled, { css, DefaultTheme, ThemedCssFunction } from 'styled-components';
import { theme } from 'theme';
import { getVwLg, getVwSm, EnumScreens, media } from 'theme/helpers/css';
import { getBreakpoints } from 'theme/selectors';

type IndentType = number | { [key: string]: number };

interface IBox {
  px?: number | IndentType;
  py?: number | IndentType;
  pt?: number | IndentType;
  pr?: number | IndentType;
  pb?: number | IndentType;
  pl?: number | IndentType;
  mx?: number | IndentType;
  my?: number | IndentType;
  mt?: number | IndentType;
  mr?: number | IndentType;
  mb?: number | IndentType;
  ml?: number | IndentType;
  bgColor?: string;
  hideBefore?: EnumScreens;
  hideAfter?: EnumScreens;
}

const paddingPropsDict = {
  px: ['padding-right', 'padding-left'],
  py: ['padding-top', 'padding-bottom'],
  pt: 'padding-top',
  pr: 'padding-right',
  pb: 'padding-bottom',
  pl: 'padding-left',
};

export const marginPropsDict = {
  mx: ['margin-right', 'margin-left'],
  my: ['margin-top', 'margin-bottom'],
  mt: 'margin-top',
  mr: 'margin-right',
  mb: 'margin-bottom',
  ml: 'margin-left',
};

export const unitTranslatorsDict = {
  [EnumScreens.sm]: getVwSm,
  [EnumScreens.lg]: getVwLg,
};

export type QueryList = { [i: string]: ThemedCssFunction<DefaultTheme> };

const getIndentKeys = props =>
  Object.keys(props).filter(k => Object.keys({ ...paddingPropsDict, ...marginPropsDict }).includes(k));

const listMediaQueries = (breakpoints: { [i: string]: number }): QueryList => {
  return Object.keys(breakpoints).reduce((queryList, breakpoint, i) => {
    const newList = { ...queryList };

    newList[breakpoint] = !i
      ? (...args: any[]) => (css as any)(...args)
      : (...args: any[]) => css`
          @media (min-width: ${breakpoints[breakpoint]}px) {
            ${(css as any)(...args)}
          }
        `;

    return newList;
  }, {});
};

const addPlainIndent = (propKey: string, value: number, bpName: EnumScreens) => {
  const indent = unitTranslatorsDict[bpName](`${value}px`);
  const propertyName = Object.keys(paddingPropsDict).includes(propKey) ? 'padding' : 'margin';
  const keyPrefix = propertyName.substr(0, 1);

  return {
    [keyPrefix + 'x']: css`
      ${propertyName}-left: ${indent};
      ${propertyName}-right: ${indent};
    `,
    [keyPrefix + 'y']: css`
      ${propertyName}-top: ${indent};
      ${propertyName}-bottom: ${indent};
    `,
    [keyPrefix + 't']: css`
      ${propertyName}-top: ${indent};
    `,
    [keyPrefix + 'r']: css`
      ${propertyName}-right: ${indent};
    `,
    [keyPrefix + 'b']: css`
      ${propertyName}-bottom: ${indent};
    `,
    [keyPrefix + 'l']: css`
      ${propertyName}-left: ${indent};
    `,
  }[propKey];
};

const addIndentStyles = (propKeys, queryList: QueryList) => props =>
  propKeys.map(propKey => {
    const prop = props[propKey];

    if (typeof prop === 'object') {
      return Object.keys(prop).map(bpName => {
        const plainStyles = addPlainIndent(propKey, prop[bpName], bpName as EnumScreens);

        return queryList[bpName]`${plainStyles}`;
      });
    }

    return [
      addPlainIndent(propKey, prop, EnumScreens.sm),
      queryList[EnumScreens.lg]`${addPlainIndent(propKey, prop, EnumScreens.lg)}`,
    ];
  });

const createIndent = props => {
  const indentKeys = getIndentKeys(props);
  const queryList = listMediaQueries(getBreakpoints(theme));
  return addIndentStyles(indentKeys, queryList);
};

const Box = styled.div<IBox>`
  ${createIndent}

  ${({ bgColor }) => bgColor && `background-color: ${bgColor}`}

  ${({ hideBefore }) =>
    hideBefore &&
    `${media.down(hideBefore)} {
      display: none!important;
    }
  `}

  ${({ hideAfter }) =>
    hideAfter &&
    css`
      ${media.up(hideAfter)} {
        display: none !important;
      }
    `}
`;

export default Box;
