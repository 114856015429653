import Flex from 'Components/Flex';
import Box from 'Components/Box';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { getVwLg } from 'theme/helpers/css';
import { Text, HandText, Title } from 'Components/Typography';
import { EnumTextType } from 'theme';
import colors from 'theme/colors';

import illustration from './img/illustration.svg';
import iconChrome from './img/icon_chrome.svg';
import iconEdge from './img/icon_edge.svg';
import iconFirefox from './img/icon_firefox.svg';
import iconOpera from './img/icon_opera.svg';
import iconYandex from './img/icon_yandex.svg';

const Illustration = styled.img.attrs({ src: illustration })`
  width: ${getVwLg('376px')};
  height: auto;
`;

const Container = styled(Flex).attrs(props => ({
  bgColor: props.theme.bgColor,
  middle: true,
  center: true,
  column: true,
}))`
  width: 100vw;
  height: 100vh;
`;

const IconWrapper = styled.a`
  &:not(:first-child) {
    margin-left: ${getVwLg('32px')};
  }
`;

const Icon = styled.img`
  width: ${getVwLg('94px')};
  height: ${getVwLg('94px')};
`;

const StyledHandText = styled(HandText)`
  font-size: ${getVwLg('38px')};
  line-height: ${getVwLg('44px')};
  margin-bottom: ${getVwLg('48px')};
`;

const theme = {
  bgColor: colors.paleGreen,
  body: {
    color: colors.green,
  },
};

const IEBanner = (): JSX.Element => (
  <ThemeProvider theme={theme}>
    <Container>
      <Illustration />

      <Box my={32}>
        <Title lvl="1" textType={EnumTextType.h3}>
          Свежему сайту нужен свежий взгляд!
        </Title>
      </Box>

      <StyledHandText align="center">
        Мы заметили, что вы пользуетесь Internet Explorer иии... <br />
        ...к сожалению, наш сайт не поддерживает данный браузер.
      </StyledHandText>

      <Text align="center">
        Но мы будем рады показать его вам в любом другом <br />
        популярном браузере или на мобильном телефоне.
      </Text>

      <Flex mt={32}>
        <IconWrapper href="https://www.google.com/intl/ru_ru/chrome/">
          <Icon src={iconChrome} />
        </IconWrapper>
        <IconWrapper href="https://www.microsoft.com/ru-ru/edge">
          <Icon src={iconEdge} />
        </IconWrapper>
        <IconWrapper href="https://www.mozilla.org/ru/firefox/new/">
          <Icon src={iconFirefox} />
        </IconWrapper>
        <IconWrapper href="https://www.opera.com/ru">
          <Icon src={iconOpera} />
        </IconWrapper>
        <IconWrapper href="https://browser.yandex.ru/">
          <Icon src={iconYandex} />
        </IconWrapper>
      </Flex>
    </Container>
  </ThemeProvider>
);

export default IEBanner;
