import React from 'react';
import IntroProvider from 'Containers/Intro/IntroProvider';
import DeviceProvider from 'Components/DeviceProvider';
import PageTransition from 'Containers/PageTransition';
import useIsIE from 'hooks/useIsIE';
import IEBanner from 'Containers/IEBanner';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const wrapRootElement = ({ element }) => {
  const isIE = useIsIE();

  return isIE ? <IEBanner /> : element;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const wrapPageElement = ({ element }) => (
  <DeviceProvider>
    <IntroProvider>
      <PageTransition>{element}</PageTransition>
    </IntroProvider>
  </DeviceProvider>
);
