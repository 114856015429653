import React, { createContext, useState } from 'react';

export const IntroContext = createContext({ isIntroShown: true, hideIntro: () => null });

const IntroProvider: React.FC = ({ children }) => {
  const [isIntroShown, setIsIntroShown] = useState(true);
  const hideIntro = () => {
    setIsIntroShown(false);
  };

  return <IntroContext.Provider value={{ isIntroShown, hideIntro }}>{children}</IntroContext.Provider>;
};

export default IntroProvider;
