import { DeviceContext, EnumDevices } from 'Components/DeviceProvider';
import { useContext } from 'react';

const useIsMobile = (): boolean => {
  const device = useContext(DeviceContext);

  return device === EnumDevices.MOBILE;
};

export default useIsMobile;
