import styled from 'styled-components';
import Box from './Box';

export interface IFlex {
  row?: boolean;
  column?: boolean;
  start?: boolean;
  end?: boolean;
  center?: boolean;
  between?: boolean;
  around?: boolean;
  top?: boolean;
  bottom?: boolean;
  middle?: boolean;
  wrap?: boolean;
  reverse?: boolean;
  basis?: string;
}

enum EnumOptionValues {
  ROW = 'row',
  COLUMN = 'column',
  START = 'flex-start',
  END = 'flex-end',
  CENTER = 'center',
  BETWEEN = 'space-between',
  AROUND = 'space-around',
}

const OptionAliases = {
  row: EnumOptionValues.ROW,
  column: EnumOptionValues.COLUMN,
  start: EnumOptionValues.START,
  end: EnumOptionValues.END,
  center: EnumOptionValues.CENTER,
  between: EnumOptionValues.BETWEEN,
  around: EnumOptionValues.AROUND,
  top: EnumOptionValues.START,
  bottom: EnumOptionValues.END,
  middle: EnumOptionValues.CENTER,
};

const getOption = (options: any, def: string) => {
  const entries = Object.entries(options);

  for (let i = 0; i < entries.length; i++) {
    const [k, v] = entries[i];
    if (v) return OptionAliases[k];
  }

  return def;
};

const Flex = styled(Box)<IFlex>`
  display: flex;
  flex-basis: ${({ basis }) => basis || 'auto'};
  flex-direction: ${({ row, column, reverse }) => getOption({ row, column }, 'row') + (reverse ? '-reverse' : '')};
  justify-content: ${({ start, end, center, between, around }) =>
    getOption({ start, end, center, between, around }, 'flex-start')};
  align-items: ${({ top, bottom, middle }) => getOption({ top, bottom, middle }, 'top')};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'no-wrap')};
`;

export default Flex;
