import mediaHelper from 'styled-media-helper';

export enum EnumScreens {
  sm = 'sm',
  lg = 'lg',
}

export const media = mediaHelper({
  [EnumScreens.sm]: 375,
  [EnumScreens.lg]: 1024,
});

export const mediaLgUp = (): string => media.up(EnumScreens.lg);
export const mediaLgDown = (): string => media.down(EnumScreens.sm);

enum Rations {
  m = 375,
  pc = 1920,
}

export const getVwSm = (px: string): string => (parseFloat(px) / Rations['m']) * 100 + 'vw';
export const getVwLg = (px: string): string => (parseFloat(px) / Rations['pc']) * 100 + 'vw';
