const colors = {
  blue: '#5D599D',
  blue90: 'rgba(93,89,157, 0.9)',
  deepBlue: '#373866',
  deepBlue60: 'rgba(55, 56, 102, 0.6)',
  paleBlue: '#F4F9FF',
  paleBlue60: 'rgba(244, 249, 255, 0.6)',
  paleBlue90: 'rgba(244, 249, 255, 0.9)',
  red: '#E58C83',
  deepRed: '#934134',
  paleRed: '#FFF8F5',
  paleRed60: 'rgba(255, 248, 245, 0.6)',
  paleRed90: 'rgba(255, 248, 245, 0.9)',
  semiPaleRed: '#F4EFED',
  green: '#51716A',
  paleGreen: '#FAFFF5',
  paleGreen60: 'rgba(250, 255, 245, 0.6)',
  semiPaleGreen: '#EAEFE8',
  purple60: 'rgba(115, 64, 124, 0.6)',
  purple10: 'rgba(115, 64, 124, 0.1)',
  purple: '#73407C',
  palePurple: '#FEF5FF',
  semiPalePurple: '#EEDDF2',
  mint: '#47726A',
  paleMint: '#F7F9F9',
  paleMint60: 'rgba(247, 249, 249, 0.6)',
  semiPaleMint: '#E3ECE8',
  dark: '#212E2B',
  moonYellow: '#FFFCD4',
  greenPea: '#2C7539',
  paleGreenPea: '#F4FFF5',
  scampi: '#5D599D',
  paleScampi: '#EFF0FF',
  tulipTree: '#EFB13F',
  paleTulipTree: '#FFFDF7',
};

export default colors;
