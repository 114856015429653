import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import useAnimation from 'hooks/useAnimationNew';
import { navigate } from 'gatsby';
import Flex from 'Components/Flex';
import colors from 'theme/colors';
import useIsMobile from 'hooks/useIsMobile';
import { URLs } from '../../constants';
import { useLocation } from '@reach/router';

import transitionPc from './json/close-pc.json';
import transitionM from './json/close-m.json';

enum TransitionStates {
  IDLE,
  LEAVE,
  ENTER,
}

interface ITransitionContext {
  navigate: (to: string, color?: string) => void;
}

const TransitionContext = createContext<ITransitionContext>({
  navigate: () => null,
});

const AnimationContainer = styled(Flex).attrs({ center: true, middle: true })`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 999;
  pointer-events: none;

  svg {
    width: 100% !important;
    height: auto !important;

    path {
      stroke: ${props => props.color} !important;
    }
  }

  @media screen and (max-aspect-ratio: 16/9) {
    flex-direction: column;

    svg {
      width: auto !important;
      height: 100% !important;
    }
  }
`;

const PageTransition: React.FC = ({ children, ...rest }) => {
  const [state, setState] = useState(TransitionStates.IDLE);
  const [color, setColor] = useState<string>(null);
  const [destination, setDestination] = useState<string>(null);
  const isMobile = useIsMobile();
  const { pathname } = useLocation();

  const completeHandler = useCallback(() => {
    if (state === TransitionStates.LEAVE) {
      navigate(destination);
      setState(TransitionStates.ENTER);
    } else if (state === TransitionStates.ENTER) {
      setState(TransitionStates.IDLE);
    }
  }, [state, destination]);

  const [container, instance] = useAnimation(
    {
      animationData: isMobile ? transitionM : transitionPc,
      loop: false,
      autoplay: false,
    },
    {
      complete: completeHandler,
    }
  );

  useEffect(() => {
    if (state === TransitionStates.ENTER && pathname === destination) {
      instance.setDirection(-1);
      instance.play();
    }
  }, [pathname, state]);

  const transtionNavigate = (to: string, color?: string) => {
    setState(TransitionStates.LEAVE);
    setDestination(to);
    setColor(color);
    instance.setDirection(1);
    instance.play();
  };

  return (
    <TransitionContext.Provider value={{ navigate: transtionNavigate }}>
      <AnimationContainer color={color} ref={container} />
      {children}
    </TransitionContext.Provider>
  );
};

const pageColors = {
  [URLs.root]: colors.paleBlue,
  [URLs.problem]: colors.paleGreen,
  [URLs.symptoms]: colors.paleRed,
  [URLs.test]: colors.palePurple,
  [URLs.advices]: colors.paleMint,
  [URLs.nightdrops]: colors.deepBlue,
};

export const TransitionLink: React.FC<{ to: string; className?: string }> = ({ to, children, className }) => {
  const { navigate } = useContext(TransitionContext);
  const handleClick = (e: any) => {
    e.preventDefault();
    navigate(to, pageColors[to]);
  };

  return (
    <a className={className} onClick={handleClick}>
      {children}
    </a>
  );
};

export default PageTransition;
