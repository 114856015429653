import styled from 'styled-components';
import { getVwLg, getVwSm, media, EnumScreens } from '../../theme/helpers/css';
import { EnumFontFamily, TextStylesLg, TextStylesSm, EnumTextType } from '../../theme';
import colors from '../../theme/colors';

type TextAlignment = 'start' | 'center' | 'right';

interface IText {
  fontFamily?: { sm: EnumFontFamily; lg: EnumFontFamily } | EnumFontFamily;
  textType?: { sm: EnumTextType; lg: EnumTextType } | EnumTextType;
  color?: string;
  align?: TextAlignment;
}

const getTextStyle = (fontFamily, textType) => {
  const textStyle: any = {};

  if (typeof fontFamily === 'string') {
    textStyle.sm = textStyle.lg = fontFamily;
  } else {
    textStyle.sm = fontFamily.sm;
    textStyle.lg = fontFamily.lg;
  }

  if (typeof textType === 'number') {
    textStyle.sm = textStyle.sm + textType;
    textStyle.lg = textStyle.lg + textType;
  } else {
    textStyle.sm = textStyle.sm + textType.sm;
    textStyle.lg = textStyle.lg + textType.lg;
  }

  return textStyle;
};

export const Text = styled.div<IText>`
  ${({ align, color, theme }) => `
    text-align: ${align || 'inherit'};
    color: ${color ?? theme.body?.color ?? 'inherit'};
  `}

  ${({ fontFamily = EnumFontFamily.futura, textType = EnumTextType.body }) => {
    const textStyle = getTextStyle(fontFamily, textType);

    return `
      ${TextStylesSm[textStyle.sm]}
      
      ${media.up(EnumScreens.lg)} {
        ${TextStylesLg[textStyle.lg]}
      }
    `;
  }}
`;

export const HandText = styled(Text).attrs(
  ({ fontFamily = EnumFontFamily.fewriter, textType = EnumTextType.body, ...props }: IText) => ({
    fontFamily,
    textType,
    ...props,
  })
)<IText>``;

export const Highlight = styled.div<{ bgColor?: string; color?: string }>`
  display: inline-block;
  line-height: inherit;
  background-color: ${props => {
    const { color, highlight } = props.theme.body || {};
    return highlight ?? color ?? props.bgColor;
  }};
  color: ${props => props.theme.bgColor || props.color || colors.blue};

  padding: 0 ${getVwSm('4px')};
  margin: 0 ${getVwSm('-4px')};

  ${media.up(EnumScreens.lg)} {
    padding: 0 ${getVwLg('4px')};
    margin: 0 ${getVwLg('-4px')};
  }
`;

interface ITitle extends IText {
  lvl?: number | string;
}

export const Title = styled(Text).attrs(
  ({ lvl = 1, fontFamily = EnumFontFamily.introscript, textType = EnumTextType.h1, ...props }: ITitle) => ({
    as: `h${lvl}`,
    fontFamily,
    textType,
    ...props,
  })
)<ITitle>``;
