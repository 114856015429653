import { getVwSm, getVwLg } from './helpers/css';

export const theme = {
  breakpoints: { sm: 375, lg: 1024 },
};

export enum EnumFontWeight {
  light = 300,
  book = 350,
  reg = 400,
  md = 500,
  demi = 600,
}

export enum EnumFontFamily {
  fewriter = 'Fewriter',
  futura = 'FuturaPT',
  introscript = 'IntroScript',
}

export enum EnumTextType {
  note,
  body,
  bodyL,
  link,
  h1,
  h2,
  h3,
  h4,
  h5,
  header,
}

export const TextStylesSm = {
  [EnumFontFamily.fewriter + EnumTextType.body]: `
      font-family: ${EnumFontFamily.fewriter}; 
      font-size: ${getVwSm('20px')}; 
      line-height: ${getVwSm('20px')};
      letter-spacing: -0.02em;
    `,
  [EnumFontFamily.fewriter + EnumTextType.bodyL]: `
      font-family: ${EnumFontFamily.fewriter}; 
      font-size: ${getVwSm('24px')}; 
      line-height: ${getVwSm('22px')};
      letter-spacing: -0.02em;
    `,
  [EnumFontFamily.fewriter + EnumTextType.h2]: `
      font-family: ${EnumFontFamily.fewriter}; 
      font-size: ${getVwSm('38px')}; 
      line-height: ${getVwSm('30px')};
      letter-spacing: -0.02em;
    `,
  [EnumFontFamily.fewriter + EnumTextType.h3]: `
      font-family: ${EnumFontFamily.fewriter}; 
      font-size: ${getVwSm('32px')}; 
      line-height: ${getVwSm('30px')};
      letter-spacing: -0.02em;
    `,
  [EnumFontFamily.futura + EnumTextType.note]: `
      font-family: ${EnumFontFamily.futura}; 
      font-size: ${getVwSm('14px')}; 
      line-height: ${getVwSm('14px')}; 
      font-weight: ${EnumFontWeight.book};
    `,
  [EnumFontFamily.futura + EnumTextType.body]: `
      font-family: ${EnumFontFamily.futura}; 
      font-size: ${getVwSm('22px')}; 
      line-height: ${getVwSm('22px')}; 
      font-weight: ${EnumFontWeight.book};
    `,
  [EnumFontFamily.futura + EnumTextType.link]: `
      font-family: ${EnumFontFamily.futura}; 
      font-size: ${getVwSm('22px')}; 
      line-height: ${getVwSm('24px')}; 
      font-weight: ${EnumFontWeight.demi};
    `,
  [EnumFontFamily.futura + EnumTextType.header]: `
      font-family: ${EnumFontFamily.futura}; 
      font-size: ${getVwSm('26px')}; 
      line-height: ${getVwSm('32px')}; 
      font-weight: ${EnumFontWeight.demi};
    `,
  [EnumFontFamily.introscript + EnumTextType.h1]: `
      font-family: ${EnumFontFamily.introscript}; 
      font-size: ${getVwSm('66px')}; 
      line-height: ${getVwSm('44px')}; 
      font-weight: ${EnumFontWeight.md};
    `,
  [EnumFontFamily.introscript + EnumTextType.h2]: `
      font-family: ${EnumFontFamily.introscript}; 
      font-size: ${getVwSm('50px')}; 
      line-height: ${getVwSm('52px')}; 
      font-weight: ${EnumFontWeight.md};
    `,
  [EnumFontFamily.introscript + EnumTextType.h3]: `
      font-family: ${EnumFontFamily.introscript}; 
      font-size: ${getVwSm('42px')}; 
      line-height: ${getVwSm('44px')}; 
      font-weight: ${EnumFontWeight.md};
    `,
  [EnumFontFamily.introscript + EnumTextType.h4]: `
      font-family: ${EnumFontFamily.introscript}; 
      font-size: ${getVwSm('34px')}; 
      line-height: ${getVwSm('34px')}; 
      font-weight: ${EnumFontWeight.md};
    `,
  [EnumFontFamily.introscript + EnumTextType.h5]: `
      font-family: ${EnumFontFamily.introscript}; 
      font-size: ${getVwSm('28px')}; 
      line-height: ${getVwSm('28px')}; 
      font-weight: ${EnumFontWeight.md};
    `,
};

export const TextStylesLg = {
  [EnumFontFamily.fewriter + EnumTextType.body]: `
      font-family: ${EnumFontFamily.fewriter}; 
      font-size: ${getVwLg('32px')}; 
      line-height: ${getVwLg('30px')};
      letter-spacing: -0.02em;
    `,
  [EnumFontFamily.fewriter + EnumTextType.h3]: `
    font-family: ${EnumFontFamily.fewriter}; 
    font-size: ${getVwLg('42px')}; 
    line-height: ${getVwLg('30px')};
    letter-spacing: -0.02em;
  `,
  [EnumFontFamily.fewriter + EnumTextType.h2]: `
    font-family: ${EnumFontFamily.fewriter}; 
    font-size: ${getVwLg('72px')}; 
    line-height: ${getVwLg('64px')};
    letter-spacing: -0.02em;
  `,
  [EnumFontFamily.futura + EnumTextType.note]: `
      font-family: ${EnumFontFamily.futura}; 
      font-size: ${getVwLg('18px')}; 
      line-height: ${getVwLg('18px')}; 
      font-weight: ${EnumFontWeight.book};
    `,
  [EnumFontFamily.futura + EnumTextType.body]: `
      font-family: ${EnumFontFamily.futura}; 
      font-size: ${getVwLg('30px')}; 
      line-height: ${getVwLg('32px')}; 
      font-weight: ${EnumFontWeight.book};
    `,
  [EnumFontFamily.futura + EnumTextType.link]: `
      font-family: ${EnumFontFamily.futura}; 
      font-size: ${getVwLg('30px')}; 
      line-height: ${getVwLg('32px')}; 
      font-weight: ${EnumFontWeight.demi};
    `,
  [EnumFontFamily.futura + EnumTextType.header]: `
    font-family: ${EnumFontFamily.futura}; 
    font-size: ${getVwLg('36px')}; 
    line-height: ${getVwLg('46px')}; 
    font-weight: ${EnumFontWeight.demi};
  `,
  [EnumFontFamily.introscript + EnumTextType.h1]: `
      font-family: ${EnumFontFamily.introscript}; 
      font-size: ${getVwLg('108px')}; 
      line-height: ${getVwLg('92px')}; 
      font-weight: ${EnumFontWeight.md};
    `,
  [EnumFontFamily.introscript + EnumTextType.h2]: `
      font-family: ${EnumFontFamily.introscript}; 
      font-size: ${getVwLg('94px')}; 
      line-height: ${getVwLg('72px')}; 
      font-weight: ${EnumFontWeight.light};
    `,
  [EnumFontFamily.introscript + EnumTextType.h3]: `
      font-family: ${EnumFontFamily.introscript}; 
      font-size: ${getVwLg('58px')}; 
      line-height: ${getVwLg('59px')}; 
      font-weight: ${EnumFontWeight.md};
    `,
};
