export const NBSP = '\u00A0';

export enum EnumPages {
  PROBLEM,
  SYMPTOMS,
  NIGHTDROPS,
  TEST,
  ADVICES,
  MAIN,
}

export enum EnumServices {
  APPOINTMENT = 'appointment',
  SUBSCRIBE = 'subscribe',
  MAP = 'map',
}

export const URLs = {
  root: '/',
  problem: '/problem',
  test: '/test',
  symptoms: '/symptoms',
  nightdrops: '/nightdrops',
  advices: '/advices',
  services: '/#services',
  makeAppointment:
    'https://www.gosuslugi.ru/10066/1?utm_source=main&utm_medium=banner&utm_campaign=federalrecommen&utm_content=10066',
};
