const useIsIE = (): boolean => {
  if (typeof window !== 'undefined') {
    const isIE = Boolean(~window?.navigator.userAgent.indexOf('Trident/'));
    return isIE;
  } else {
    return false;
  }
};

export default useIsIE;
