import Lottie, { AnimationConfigWithData, AnimationEventCallback, AnimationEventName, AnimationItem } from 'lottie-web';
import { MutableRefObject, useEffect, useRef, useState } from 'react';

type Options = Omit<AnimationConfigWithData, 'container'>;

interface IHandlers {
  [k: string]: AnimationEventCallback<any>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useAnimation = (options: Options, handlers?: IHandlers): [MutableRefObject<HTMLDivElement>, AnimationItem] => {
  const container = useRef<HTMLDivElement>();
  const [instance, setInstance] = useState({} as AnimationItem);

  const createAnimation = () =>
    Lottie.loadAnimation({
      renderer: 'svg',
      container: container.current,
      ...options,
    });

  useEffect(() => {
    if (container.current && options.animationData) {
      const instance = createAnimation();

      setInstance(instance);

      return () => {
        instance.destroy();
      };
    }
  }, [options.animationData]);

  useEffect(() => {
    if (instance.isLoaded && handlers) {
      const entries = Object.entries(handlers);

      entries.forEach(([k, cb]) => {
        if (instance._cbs) {
          instance.addEventListener(k as AnimationEventName, cb);
        }
      });

      return () => {
        entries.forEach(([k, cb]) => {
          if (instance._cbs) {
            instance.removeEventListener(k as AnimationEventName, cb);
          }
        });
      };
    }
  }, [instance.isLoaded, handlers]);

  return [container, instance];
};

export default useAnimation;
